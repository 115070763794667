import request from '@/api/request'

/**
 * 获取列表
 */
export function getModelList(query) {
    return request({
        url: '/system/model/list',
        method: 'get',
        params: query
      });
}