<template>
  <div class="home">
    <div class="search">
      <van-search
        v-model="searchStr"
        @blur="getList"
        left-icon="none"
        right-icon="search"
        shape="round"
        placeholder="搜索"
      />
    </div>
    <div class="tab_line">
      <div :class="queryParams.modelType == item.value ? 'tab_item_active' : 'tab_item'" v-for="(item, index) in tabList" :key="index" @click="onTab(item.value)">{{ item.name }}</div>
    </div>
    <div class="main">
      <div class="main_list">
        <div class="list_item" v-for="(item, index) in listData" :key="index" @click="toPage(index)">
          <!-- <img :src="require(`../assets/img/${item.img}.png`)" alt=""> -->
          <img :src="item.coverlmg" alt="">
          <div>{{ item.modelName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getModelList } from '@/api/model';

export default {
  name: 'HomeView',
  data () {
    return {
      listData: [],
      searchStr: '',
      tabList: [
        { name: '植物', value: '0' },
        { name: '绳结', value: '1' },
        { name: '动物', value: '2' },
        { name: '地形', value: '3' },
        { name: '露营', value: '4' },
        { name: '野炊', value: '5' }
      ],
      tabIndex: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 1000,
        modelName: '',
        modelType: '0'
      }
    }
  },
  watch: {
    tabIndex: {
      handler(oldVal, newVal){
        this.queryParams.modelName = newVal
      },
      deep: true
    }
  },
  mounted() {
    if (localStorage.getItem('tabIndex')) {
      this.queryParams.modelType = localStorage.getItem('tabIndex')
    }
    this.getList()
  },
  methods: {
    getList() {
      this.listData = []
      getModelList(this.queryParams).then((res) => {
        console.log(res);
        this.listData = res.rows
        // this.listData = [
        //   { modelName: '苎麻', coverlmg: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/zhuMa.png', modelUrl: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/zhuMa.glb' },
        //   { modelName: '车前草', coverlmg: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/cheQianCao.png', modelUrl: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/cheQianCao.glb'  },
        //   { modelName: '仙鹤草', coverlmg: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/xianHeCao.png', modelUrl: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/xianHeCao.glb'  },
        //   { modelName: '乌蔹莓', coverlmg: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/wuLianMei.png', modelUrl: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/wuLianMei.glb'  },
        //   { modelName: '艾草', coverlmg: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/aiCao.png', modelUrl: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/aiCao.glb'  },
        //   { modelName: '翅果菊', coverlmg: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/chiGuoJu.png', modelUrl: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/chiGuoJu.glb'  },
        //   { modelName: '马齿苋', coverlmg: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/maChiXian.png', modelUrl: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/maChiXian.glb'  },
        //   { modelName: '蒲公英', coverlmg: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/puGongYing.png', modelUrl: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/puGongYing.glb'  },
        // ]
      })
      return
      this.listData = []
      if (this.tabIndex == 0) {
        // this.listData = [
        //   { name: '苎麻', img: 'zhuMa', },
        //   { name: '车前草', img: 'cheQianCao', },
        //   { name: '仙鹤草', img: 'xianHeCao', },
        //   { name: '乌蔹莓', img: 'wuLianMei',  },
        //   { name: '艾草', img: 'aiCao',  },
        //   { name: '翅果菊', img: 'chiGuoJu',  },
        //   { name: '马齿苋', img: 'maChiXian',  },
        //   { name: '蒲公英', img: 'puGongYing',  },
        // ]
        this.listData = [
          { name: '苎麻', img: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/zhuMa.png', url: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/zhuMa.glb' },
          { name: '车前草', img: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/cheQianCao.png', url: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/cheQianCao.glb'  },
          { name: '仙鹤草', img: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/xianHeCao.png', url: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/xianHeCao.glb'  },
          { name: '乌蔹莓', img: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/wuLianMei.png', url: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/wuLianMei.glb'  },
          { name: '艾草', img: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/aiCao.png', url: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/aiCao.glb'  },
          { name: '翅果菊', img: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/chiGuoJu.png', url: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/chiGuoJu.glb'  },
          { name: '马齿苋', img: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/maChiXian.png', url: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/maChiXian.glb'  },
          { name: '蒲公英', img: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/puGongYing.png', url: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/puGongYing.glb'  },
        ]
      } else if (this.tabIndex == 3) {
        this.listData = [
          { name: '掩体', img: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/yanTi.png', url: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/yanTi.glb' }
        ]
      } else if (this.tabIndex == 5) {
        this.listData = [
          { name: '散烟灶', img: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/sanYanZao.png', url: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/sanYanZao.glb' },
          { name: '散烟灶解剖模型', img: 'https://1240mx.oss-cn-beijing.aliyuncs.com/img/zhiwu/sanYanZao2.png', url: 'https://1240mx.oss-cn-beijing.aliyuncs.com/glb/sanYanZao2.glb' }
        ]
      } else {
        this.listData = []
      }
      if (this.searchStr) {
        this.listData = this.listData.filter(item => (item.name.indexOf(this.searchStr) >= 0))
      }
    },
    toPage(i) {
      this.$router.push({
        path: '/moduleView',
        query: {
          list: this.listData,
          index: i
        }
      })
    },
    onTab(value) {
      this.queryParams.modelType = value
      localStorage.setItem('tabIndex', this.queryParams.modelType)
      this.getList()
    }
  }
}
</script>
<style lang="css" scoped>
.home {
  overflow: hidden;
}
.search {
  padding-top: 10px;
}
.van-search__content {
  background: #ffffff;
}
.van-search__content--round {
  border: 1px solid #D7D7D7;
}
.tab_line {
  height: 28px;
  padding-left: 5px;
  padding-right: 15px;
  display: flex;
}
.tab_item {
  flex: 1;
  font-size: 18px;
  color: #999999;
  font-weight: 600;
  text-align: right;
  line-height: 25px;
  overflow: hidden;
}
.tab_item_active {
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  color: #005844;
  text-align: right;
  line-height: 25px;
  overflow: hidden;
  background-image: url('@/assets/img/title_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.main {
  height: calc(100vh - 130px);
  overflow-y: auto;
  padding: 1rem 0;
}

.main_list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.list_item {
  width: calc((100% - 30px) / 2);
  height: 130px;
  margin: 0 0 10px 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  text-align: center;
  overflow: hidden;
}

.list_item img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  vertical-align: middle;
}

.list_item div {
  padding: 5px;
}
</style>
