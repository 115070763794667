import axios from 'axios'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const request = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000
})

// request拦截器
request.interceptors.request.use(config => {
  // get请求映射params参数
  // if (config.method === 'get' && config.params) {
  //   let url = config.url;
  //   url = url.slice(0, -1);
  //   config.params = {};
  //   config.url = url;
  // }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
request.interceptors.response.use(res => {
      if (res.data.code === 200) {
        return res.data
      }
    },
    error => {
      console.log('err' + error)
      return Promise.reject(error)
    }
)

export default request
